:root {
  /*
  --bold_bg: #990000;
  --bold_fg: white;
  --link_fg: #990000;
  --secondary_bg: #FFC72C;
  --link_active: #FFC72C;
  --primary_color:#990000;
  --secondary_color:#FFC72C;
  --tertiary_color:#E25822;
*/

  --bold_bg: #C51723;
  --bold_fg: white;
  --link_fg: #183D69;
  --secondary_bg: #FCC921;
  --link_active: #C51723;
  --link_primary_active:hsl(356deg 43% 49%);
  --primary_color:#C51723;
  --secondary_color:#FCC921;
  --tertiary_color:#183D69;
  --link_tertiary_active:#56759A;
  --lighter_gray:hsl(0deg, 2%, 90%);
  --light_gray:#AAA6A6;
  --mid_gray:#707070;
  --dark_gray:#403C3C;
  --warning:#C51723;

  --section_margin:2rem;
  --header_margin:2rem;
  --main_width:900px;
  --wide_elt_min_width:340px;
  --bottom_box_shadow: 0px 1px 7px 1px rgba(0, 0, 0, 0.1);
  --min_button_width:70px;
}

.app{
  min-height:100vh;
  display:flex;
  flex-direction:column;
  align-items:flex-start;
}
.vertical-filler
{
  flex-grow: 1;
  height:100%;
}
.main-wrapper{
  max-width:var(--main_width);
  margin-left:auto;
  margin-right:auto;
  vertical-align: top;
}
.main
{
  padding: 16px;
  margin-bottom:auto;
  vertical-align: top;
}
.isolated
{
  isolation: isolate;
}

.card-header
{
  background-color: var(--bold_bg);
  color: var(--bold_fg);
}
.section
{
  margin-bottom:var(--section_margin);
}

h1
{
  text-align: center;
}
h2{
  text-align: left;
}
.section-heading
{
  margin-bottom:1rem;
}
p{
  margin:1rem 0px;
}
a
{
  color: var(--link_fg);
  text-decoration:none;
}
a:active, a:hover
{
  color:var(--secondary_color);
}

p > a{
  text-decoration: underline;
}
p > a:active, a:hover{
  text-decoration: none;
}
.stealthy-link{
  color:black;
  text-decoration:none;
}
.stealthy-link:active, .stealthy-link:hover{
  color:black;
  text-decoration: none;
}
 
.link-btn
{
  display:inline-block;
  font-weight:700;
  background-color: var(--bold_bg);
  color: var(--bold_fg);
  border-color: var(--bold_bg);
  padding:8px;
  border-radius:6px;
  margin-right:4px;
  margin-top:4px;
  margin-bottom:4px;
  text-decoration:none;
  border:none;
  cursor:pointer;
}
.link-btn:hover, .link-btn:active, .link-btn:focus, .link-btn:active:focus
{
  background-color: var(--link_primary_active);
  border:none;
  color:var(--bold_fg);
  cursor:pointer;
}

.link-btn-hollow
{
  display:inline-block;
  font-weight:700;
  
  padding:8px;
  border-radius:6px;
  margin-right:4px;
  margin-top:4px;
  margin-bottom:4px;
  text-decoration:none;
  border:none;
  cursor:pointer;
  min-width:var(--min_button_width);
  text-align:center;
  color:var(--tertiary_color);
  background-color:white;
  border:2px solid var(--tertiary_color);
  
}
.link-btn-hollow:hover, .link-btn-hollow:active, .link-btn-hollow:focus, .link-btn-hollow:active:focus
{
  border:2px solid var(--link_tertiary_active);
  color:var(--link_tertiary_active);
  cursor:pointer;
}
.button-series-label
{
  display:inline-block;
  position:relative;
  font-weight:500;
  color: var(--bold_fg);
  padding:8px;
  border-radius:6px 6px 6px 6px;
  text-align: center;
  margin-right:4px;
  margin-top:4px;
  margin-bottom:4px;
  text-decoration:none;
  border:2px solid var(--tertiary_color);
  background-color:var(--tertiary_color);
  border-color:var(--tertiary_color);
  min-width:var(--min_button_width);
}
.button-series
{
  margin-left:-16px;
  position:relative;
  border-radius:0px 16px 16px 0px;
  min-width:var(--min_button_width);
  text-align:center;
}

.link-btn-tertiary
{
  display:inline-block;
  position:relative;
  font-weight:500;
  color: var(--bold_fg);
  padding:8px;
  border-radius:6px;
  margin-right:4px;
  margin-top:4px;
  margin-bottom:4px;
  text-decoration:none;
  border:2px solid var(--tertiary_color);
  cursor:pointer;
  min-width:var(--min_button_width);
  text-align: center;

  background-color:var(--tertiary_color);
  border-color:var(--tertiary_color);
}
.link-btn-tertiary:hover, .link-btn-tertiary:active, .link-btn-tertiary:focus, .link-btn-tertiary:active:focus
{
  background-color: var(--link_tertiary_active);
  border:2px solid var(--link_tertiary_active);
  color:var(--bold_fg);
  cursor:pointer;
}


.div-btn
{
  background-color: var(--bold_bg);
  color: var(--bold_fg);
  border-color: var(--bold_bg);
  border-radius: .25rem;
  text-align: center;
  display: inline-block;
  font-size: 1.5rem;
  padding: 1rem;
}
.div-btn:hover, .div-btn:active
{
  background-color: var(--link_active);
  border-color: var(--link_active);
}

/* from wordpress */
.aligncenter{
  text-align: center;
}

