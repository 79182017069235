.footer
{
    background-color: var(--secondary_color);
    padding:16px;
    width:100%;
    margin-top:auto;
}
.footer-inner{
    max-width:var(--main_width);
    margin:auto;
}
.footer-image
{
    display: inline-block;
    vertical-align: 50%;    
}
.footer-links
{
    display:inline-block;
    color:black;
    margin-top:auto;
    margin-bottom:auto;
    margin-left:32px;
    vertical-align: top;
}
.footer-links-wrapper
{
    vertical-align: top;
    display:inline-block;
}
.footer-links ul
{
    margin:0px;
    padding:0px;
}

.footer-links li
{
    list-style-type: none;
}
.footer-links a
{
    color:black;
}
.footer-copyright
{
    font-size:.75rem;
    color:black;
    text-align:center;
} 