.season-list
{
    
}

/* For navigation bars inside pages, see SeasonList */
.seasonList-nav
{
  font-size:1.1rem;
  font-weight:500;
  width:100%;
  /*max-width:600px;*/
  min-width:var(--wide_elt_min_width);
  /*border:1px solid var(--primary_color);*/
  margin-bottom:1rem;
  margin-left:auto;
  margin-right:auto;
}
.seasonList-nav-left
{
  display:inline-block;
  margin-right:auto;
  text-align:left;
  width:20%;
  /* border:1px solid var(--primary_color); */
}
.seasonList-nav-filler
{
  display:inline-block;
  width:60%;
  text-align: center;
  /* border:1px solid var(--primary_color); */
}
.seasonList-nav-right
{
  display:inline-block;
  margin-left:auto;
  text-align:right;
  width:20%;
  /* border:1px solid var(--primary_color); */
}


.season-select
{
  /* border:1px solid var(--primary_color); */
  font-size:1rem;
}
.season-select__control
{
    max-width:fit-content;
    margin:auto;
}
.season-select__placeholder
{
    color:var(--dark_gray) !important;
}

/** Styles for the EventDetailPage **/
.event-title
{
    text-align:left;
}
.event-detail-header
{
    vertical-align:top;
    margin-bottom:var(--header_margin);
}
.event-detail-header-info
{
    vertical-align:top;
    display:inline-block;
    width:50%;
    min-width:200px;
}
.event-detail-header-image
{
    display:inline-block;
    width:33%;
    min-width:300px;
}
.eventHeader-date-wrapper
{
  margin-bottom:.5rem;
}
.event-detail-header-buttons
{
    margin-top:24px;
}