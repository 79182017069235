.eventListItem-row{
    display:flex;
    flex-direction: row;
    flex-wrap:wrap;
    align-items:stretch;
    justify-content: center;
}

.eventListItem{
    display: flex;
    flex-direction:column;
    margin-left:auto;
    margin-right:auto;
    margin-bottom:16px;
    min-height:200px;
    min-width:var(--wide_elt_min_width);
    box-shadow: 3px 3px 7px 5px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    /*max-width:600px;*/
}
@media(min-width:800px)
{
    .eventListItem
    {
        min-width:600px;
    }
}
.eventListItem-label{
    font-family: interstate-condensed,sans-serif;
    background-color: var(--secondary_bg);
    border-radius: 6px 6px 0px 0px;
    padding:8px;
    font-weight:900;
    font-size:1rem;
    text-transform:uppercase;
}
.eventListItem-main{
    padding:12px;
    flex-grow: 1;
}
.eventListItem-header{
    font-size:1.5rem;
    font-weight:600;
    margin-bottom:.5rem;
}
.eventListItem-body{

}
.eventListItem-footer{
    /*display:flex;*/
    padding:8px;
}
.eventListItem-location-pin
{
    display:inline-block;
    font-size:1.75rem;
    margin-right:6px;
    /** both for vert align **/
    vertical-align:top;    
    line-height:0;
    min-width:25px;
}
.eventListItem-location-text
{
    display:inline-block;
}
.eventHeader-date-text
{
    display:inline-block;
    vertical-align: middle;
    font-weight:600;
    line-height:1;
}
.eventListItem-location-name
{
    font-weight:600;
    line-height:1;
}
.eventListItem-location-city
{
    font-size:.75rem;
}
