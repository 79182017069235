.navbar-wrapper{
  width:100%;
  height:75px;
  background-color: var(--secondary_color);
  box-shadow: 0px 1px 7px 1px rgba(0, 0, 0, 0.1);
  margin-bottom:32px;
}
.navbar {
    width:100%;
    max-width:var(--main_width);    
    display: flex;
    align-items: center;
    position: relative;
    padding: 0.5rem 0rem;
    margin:auto;
    font-weight:600;
  }
  .navbar a
  {
    color:black;
  }
  .navbar-logo{
    margin-right:auto;
  }
  .navigation-menu {
    margin-left: auto;
  }

  .navigation-permanent{

  }

  .navbar ul {
    display: flex;
    padding: 0;
    margin-bottom:0px;
  }
  .navigation-permanent li {
    list-style-type: none;
    margin: 0 1rem;
  }
  .navigation-permanent li a {
    text-decoration: none;
    display: block;
    width: 100%;
  }
  .navigation-menu li {
    list-style-type: none;
    margin: 0 1rem;
  }
  .navigation-menu li a {
    text-decoration: none;
    display: block;
    width: 100%;
  }

  .navigation-hamburger
  {
    border: 0;
    height: 40px;
    width: 40px;
    margin-right:16px;
    padding: 0.5rem;
    background-color: var(--secondary_color);
    cursor: pointer;
    display: none;
    color: black;
}
.navigation-hamburger:focus
{
    outline:none;
}

@media screen and (max-width: 768px) {
    .navigation-hamburger {
      display: block;
    }
}
  @media screen and (max-width: 768px) {
    .navigation-menu ul {
      display: none;
    }
}

@media screen and (max-width: 768px) {
    .navigation-menu ul {
      position: absolute;
      top: 75px;
      left: 0;
      flex-direction: column;
      background-color: white;
      width:100%;
      z-index: 1;
      padding:16px;
    }
    .navigation-menu li a {
      width: 100%;
      padding:8px;
    }
    .navigation-menu li:hover {
      background-color: #eee;
    }
    .navigation-menu.expanded ul {
        display: block;
      }
}