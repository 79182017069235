.imageHeader-wrapper
{
    width:100%;
    margin-bottom:var(--header_margin);
}
.imageHeader-title
{
    margin-bottom:8px;
    text-align:left;
}
.imageHeader-text
{
    display:inline-block;
    vertical-align: top;
}
.imageHeader-text-inner
{
    display:flex;
    flex-direction:column;
    height:200px;
}
@media(max-width:500px)
{
    .imageHeader-text-inner
    {
        height:auto;
    }
}
.imageHeader-image
{
    display:inline-block;
    margin-right:32px;
    vertical-align: bottom;
}
.imageHeader-image > img
{
    margin-left:auto;
    display:block;
    overflow:clip;
    height:200px;
}
.imageHeader-subtitle
{
    font-size:1rem;
    margin-bottom:.5rem;
    font-weight:700;
}
.imageHeader-dateline
{
    font-size:.85rem;
}
.imageHeader-body
{
    flex-grow: 1;
}
.imageHeader-footer
{
    margin-top:auto;
}