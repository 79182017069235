.news-dateline
{
    font-style:italic;
}
.news-header
{

}
.news-header-image
{
    
}



.newsListItem-row{
    display:flex;
    flex-direction: row;
    flex-wrap:wrap;
    justify-content: center;
}
.newsListItem
{
    display: flex;
    flex-direction:column;
    margin-bottom:8px;
    box-shadow: 3px 3px 7px 5px rgba(0, 0, 0, 0.1);
    border-radius:8px;
    max-width:300px;
    margin-right:8px;
}
.newsListItem-title
{
    font-size:1.25rem;
    line-height:1.25;
    font-weight:700;
    text-align:left;
    margin-bottom:.5rem;
}
.newsListItem-bgImage
{
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 225px;
    border-radius:6px 6px 0px 0px;
}
.newsListItem-text
{
    display:inline-block;
    vertical-align: top;
    padding:16px;
    flex-grow: 1;
    background-color: white;
    border-radius:0px 0px 8px 8px;
}
.newsListItem-dateline
{
    font-size:.85rem;
    font-style:italic;
    margin-bottom:4px;
}
.newsListItem-jumplink
{
    text-align:right;
    font-family:'interstate-condensed',sans-serif;
    font-weight:700;
    text-transform:uppercase;
    margin-top:8px;
}

.latestNewsWidget
{
    width:100%;
    background-color: var(--lighter_gray);
    padding:24px;
}
.latestNewsWidget-header
{
    text-align:center;
    margin-bottom:1rem;
}
.latestNewsWidget-readMore
{
    text-align: center;
    margin-top:16px;
}