.wideTile {
    display: flex;
    flex-direction: column;
    min-height:200px;
    margin-left:auto;
    margin-right:auto;
    box-shadow: 3px 3px 7px 5px rgba(0, 0, 0, 0.1);
    margin-bottom: 1rem;
    background: #fff;
    line-height: 1.4;
    font-family: sans-serif;
    border-radius: 5px;
    overflow: hidden;
    z-index: 0;
  }
  @media(max-width:400px)
  {
    .wideTile
    {
      max-width:340px;
      margin-left:0px;
      margin-right:0px;
    }
  }
  .wideTile h2
  {
    text-align: left;
  }
  .wideTile .meta {
    position: relative;
    z-index: 0;
    height: 200px;
  }
  .wideTile .photo {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-size: cover;
    background-position: center;
  }
  .wideTile .details {
    position: absolute;
    top: 0;
    bottom: 0;
    left: -100%;
    margin: auto;
    transition: left 0.2s;
    background: rgba(0, 0, 0, 0.6);
    color: #fff;
    padding: 10px;
    width: 100%;
    font-size: 0.9rem;
  }
  .wideTile .body {
    padding: 1rem;
    background: #fff;
    display:flex;
    flex-direction: column;
    flex:1;
  }
  .wideTile .body .read-more {
    text-align: left;
    margin-top:auto;
  }
  .wideTile-filler
  {
    height:100%;
  }

  .wideTile p {
    position: relative;
    margin: 1rem 0 0;
  }
  .wideTile:hover .details {
    left: 0%;
  }
  @media (min-width: 640px) {
    .wideTile {
      flex-direction: row;      
      /*max-width: 700px;*/
    }
    .wideTile .meta {
      flex-basis: 30%;
      height: auto;
    }
    .wideTile .body {
      flex-basis: 70%;
    }
    .wideTile.alt {
      flex-direction: row-reverse;
    }
    .wideTile.alt .details {
      padding-left: 25px;
    }
  }