.featureBox-row{
    display:flex;
    flex-direction: row;
    flex-wrap:wrap;
    /*align-items:stretch;*/
    justify-content: space-around;
    /*justify-content: center;*/
}
@media (max-width: 850px) {
    .featureBox-row{
        justify-content: center;
    }
}


.featureBox{
    display: flex;
    flex-direction:column;
    width:31%;
    min-width:250px;
    margin:8px;
    min-height:200px;
    /*border:2px solid;*/
    box-shadow: 3px 3px 7px 5px rgba(0, 0, 0, 0.1);
    border-color: var(--mid_gray);
    border-radius: 8px;
}
@media(max-width:570px)
{
    .featureBox{
        width:100%;
        max-width:500px;
    }
}
.featureBox-label{
    font-family:'interstate-condensed',sans-serif;
    background-color: var(--secondary_bg);
    border-radius: 6px 6px 0px 0px;
    padding:8px;
    font-weight:900;
    font-size:.85rem;
    text-transform:uppercase;
}
.featureBox-main{
    padding:8px;
    flex-grow: 1;
}
.featureBox-header{
    font-size:1.25rem;
    font-weight:600;
}
.featureBox-body{

}
.featureBox-footer{
    display:flex;
    padding:8px;
}
